(function($) {
	function filter(items, controls) {
		var showOnPopulated = arguments[2];
		var showOnUnpopulated = arguments[3];
		var populated = false;
		items.forEach(function (item) {
			var display = controls.every(function (control) {
				var values, valueMatch, selected, unselected;
				values = control.key == 'text' ? $(item).text() : $(item).data(control.key);
				valueMatch = true;
				if (typeof values !== 'undefined') {
					if (control.inclusive || control.key == 'text') {
						selected = control.options.reduce(function (selected, option) {
							if (control.key == 'text') {
								selected.push($(option).val());
							} else if ($(option).hasClass('selected') || $(option).hasClass('checked') || $(option).is(':selected') || $(option).is(':checked')) {
								selected.push($(option).data(control.key));
							}
							return selected;
						}, []);
						valueMatch = String(values).split(control.delimiter).some(function (value) {
							return selected.some(function (option) {
								return typeof option == 'undefined' || option == '' || control.filter(option, value);
							});
						});
					} else {
						unselected = control.options.reduce(function (unselected, option) {
							if ($(option).attr('type') !== 'hidden' && !$(option).hasClass('selected') && !$(option).hasClass('checked') && !$(option).is(':selected') && !$(option).is(':checked')) {
								unselected.push($(option).data(control.key));
							}
							return unselected;
						}, []);
						valueMatch = String(values).split(control.delimiter).every(function (value) {
							return unselected.every(function (option) {
								return option == '' || !control.filter(option, value);
							});
						});
					}
				}
				return valueMatch;
			});
			if (display) {
				$(item).css('display', '');
				populated = true;
			} else {
				$(item).css('display', 'none');
			}
		});
		if (populated) {
			if (typeof showOnPopulated !== 'undefined') $(showOnPopulated).show();
			if (typeof showOnUnpopulated !== 'undefined') $(showOnUnpopulated).hide();
		} else {
			if (typeof showOnPopulated !== 'undefined') $(showOnPopulated).hide();
			if (typeof showOnUnpopulated !== 'undefined') $(showOnUnpopulated).show();
		}
	};

  window.sectionFilter = filter.bind(null, $('.enginece_section_list > li').toArray(), [
		{
			key: 'engineceSubjectCode',
			options: $('#section_filter_subject > select > option').toArray(),
			delimiter: ' ',
			inclusive: true,
			filter: function (optionValue, itemValue) { return optionValue === itemValue }
		},
		{
			key: 'engineceDelivery',
			options: $('#section_filter_delivery > select > option').toArray(),
			delimiter: ' ',
			inclusive: true,
			filter: function (optionValue, itemValue) { return optionValue === itemValue }
		},
		{
			key: 'engineceDeliveryAttributeCode',
			options: $('#section_filter_delivery_attribute_code > select > option').toArray(),
			delimiter: ' ',
			inclusive: true,
			filter: function (optionValue, itemValue) { return optionValue === itemValue }
		},
		{
			key: 'engineceStartTimeMinimum',
			options: $('#section_filter_start_time > select > option').toArray(),
			delimiter: ' ',
			inclusive: true,
			filter: function (optionValue, itemValue) { return optionValue <= itemValue }
		},
		{
			key: 'engineceEndTimeMaximum',
			options: $('#section_filter_end_time > select > option').toArray(),
			delimiter: ' ',
			inclusive: true,
			filter: function (optionValue, itemValue) { return optionValue >= itemValue }
		},
		{
			key: 'engineceWeekday',
			options: $('#section_filter_weekdays > div > input').toArray(),
			delimiter: ' ',
			inclusive: false,
			filter: function (optionValue, itemValue) { return optionValue === itemValue }
		},
		{
			key: 'text',
			options: $('#section_filter_text > input').toArray(),
			delimiter: "\0",
			inclusive: true,
			filter: function (optionValue, itemValue) { return itemValue.normalize('NFKD').toUpperCase().indexOf(optionValue.normalize('NFKD').toUpperCase()) !== -1; }
		}
	].concat(
		window.location.search !== '?testinginproduction' ? [] : [
		{
			key: 'engineceIsFull',
			options: $('#section_filter_full, #section_filter_not_full').toArray(),
			delimiter: ' ',
			inclusive: false,
			filter: function (optionValue, itemValue) { return optionValue === itemValue }
		},
		{
			key: 'engineceIsEnrolling',
			options: $('#section_filter_enrolling, #section_filter_not_enrolling').toArray(),
			delimiter: ' ',
			inclusive: false,
			filter: function (optionValue, itemValue) { return optionValue === itemValue }
		}
	]), '.enginece_section_list', '.enginece_section_list_empty');

	// Throttle Utility
	function throttle(fn, time) {
		var currentTimer;
		return function () {
			if (typeof currentTimer !== 'undefined') window.clearTimeout(currentTimer);
			currentTimer = window.setTimeout(fn, time);
			if (event.which == 13) event.preventDefault(); //prevent enter key from submitting
		};
	}

	// Course List Filtering Event Management
	var sectionFilterText = throttle(sectionFilter, 500);
	var sectionFilterMain = throttle(sectionFilter, 10);
	$('#section_filter_text > input').on({keypress: sectionFilterText, change: sectionFilterText, input: sectionFilterText});
	$('#section_filter_subject > select, #section_filter_delivery > select, #section_filter_delivery_attribute_code > select, #section_filter_start_time > select, #section_filter_end_time > select, #section_filter_weekdays > div > input').change(sectionFilterMain);
	if (document.getElementById('portfilioFilter')) sectionFilter(); //apply filters for cached form fields, i.e. form fields already populated when page is loaded
})(jQuery);
