(function($) {
  jQuery.expr[':'].contains = function(a, i, m) {
    return jQuery(a).text().toUpperCase().indexOf(m[3].toUpperCase()) >= 0;
  };
  var Toolbox = {
    programListFilter: function() {
      $('.program-listings .program-listing').hide();
      $('.program-listings .no-program-results').hide();
      var SearchTermValue= $('#portfolioFilter input#search-term').val();
      var AreaOfInterestValue= $('#portfolioFilter select#area-of-interest option:selected').val();
      var AreaOfInterestText= $('#portfolioFilter select#area-of-interest option:selected').text();
      var LearningFormatValue= $('#portfolioFilter select#learning-format option:selected').val();
      var LearningFormatText= $('#portfolioFilter select#learning-format option:selected').text();
      var ProgramTypeValue= $('#portfolioFilter select#program-type option:selected').val();
      var ProgramTypeText= $('#portfolioFilter select#program-type option:selected').text();
      var SemesterValue= $('#portfolioFilter select#semester option:selected').val();
      var SemesterText= $('#portfolioFilter select#semester option:selected').text();
      $('.program-listings .program-listing:contains("'+SearchTermValue+'")[data-area-of-interest*="'+AreaOfInterestValue+'"][data-learning-format*="'+LearningFormatValue+'"][data-program-type*="'+ProgramTypeValue+'"][data-session*="'+SemesterValue+'"]').fadeIn(250);
      if ($('.program-listings .program-listing:visible').length==0) {
        $('.program-listings .no-program-results').fadeIn(250);
      }
      $('#filtered-search-term span').text("\""+SearchTermValue+"\"");
      $('#filtered-area-of-interest span').text(AreaOfInterestText);
      $('#filtered-learning-format span').text(LearningFormatText);
      $('#filtered-program-type span').text(ProgramTypeText);
      $('#filtered-semester span').text(SemesterText);


      if (SearchTermValue) {
        $('#filtered-search-term').removeClass('hidden');
      } else {
        $('#filtered-search-term').addClass('hidden');
      }
      if (AreaOfInterestValue==="all-areas") {
        $('#filtered-area-of-interest').addClass('hidden');
      } else {
        $('#filtered-area-of-interest').removeClass('hidden');
      }
      if (LearningFormatValue==="all-formats") {
        $('#filtered-learning-format').addClass('hidden');
      } else {
        $('#filtered-learning-format').removeClass('hidden');
      }
      if (ProgramTypeValue==="all-types") {
        $('#filtered-program-type').addClass('hidden');
      } else {
        $('#filtered-program-type').removeClass('hidden');
      }
      if (SemesterValue==="all-sessions") {
        $('#filtered-semester').addClass('hidden');
      } else {
        $('#filtered-semester').removeClass('hidden');
      }

      if (AreaOfInterestValue!=="all-areas" || LearningFormatValue!=="all-formats" || ProgramTypeValue!=="all-types" || SemesterValue!=="all-sessions" || SearchTermValue) {
        $('#filtered-all').addClass('hidden');
      } else {
        $('#filtered-all').removeClass('hidden');
      }
    }
  };
  // Attach Toolbox to window
  window.Toolbox = Toolbox;
})(jQuery);
