function onScrollTOC(event){
  var scrollPos = jQuery(document).scrollTop();
  jQuery('.wp-block-aioseo-table-of-contents a').each(function () {
      var currLink = jQuery(this);
      var refElement = jQuery("h2"+currLink.attr("href")).closest('.pace-program-block-group');
      if (jQuery(refElement).length) {
        if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
          jQuery('.wp-block-aioseo-table-of-contents ul li a').removeClass("active");
          currLink.addClass("active");
        }
        else{
          currLink.removeClass("active");
        }
      }
  });
}
